import axios from 'axios';
// import router from '../routerManuaConfig'
import router from '../router/index'
import store from "../store";
import Vue from 'vue';


let base = '';
let executionsNum = 0
// 如果是IIS部署，用这个，因为 IIS 只能是 CORS 跨域，不能代理
//let base = process.env.NODE_ENV=="production"? 'http://api.training.wanghuolou.cn:82':'';
//let base = process.env.NODE_ENV=="production"? 'http://192.168.1.4:5001':'';

//let base = process.env.NODE_ENV=="production"? 'http://192.168.1.4:1004':'';

// 请求延时
axios.defaults.timeout = 60000

var storeTemp = store;
axios.interceptors.request.use(
    config => {
        var curTime = new Date()
        var expiretime = new Date(Date.parse(storeTemp.state.tokenExpire))
        // if (storeTemp.state.token && (curTime < expiretime && storeTemp.state.tokenExpire)) {
        if (storeTemp.state.token) {
            // 判断是否存在token，如果存在的话，则每个http header都加上token
            config.headers.Authorization = "Bearer " + storeTemp.state.token;
        }

        saveRefreshtime();

        return config;
    },
    err => {
        return Promise.reject(err);
    }
);

// http response 拦截器
axios.interceptors.response.use(
    Response => {
        if (Response.data.Status == 401) {
            var curTime = new Date()
            var refreshtime = new Date(Date.parse(window.localStorage.refreshtime))
            // // 在用户操作的活跃期内
            // if (window.localStorage.refreshtime && (curTime <= refreshtime)) {
            //     return  refreshToken({token: window.localStorage.Token}).then((res) => {
            //         if (res.Success) {
            //             store.commit("saveToken", res.Response.token);
            //             var curTime = new Date();
            //             var expiredate = new Date(curTime.setSeconds(curTime.getSeconds() + res.Response.expiresIn));
            //             store.commit("saveTokenExpire", expiredate);
            //             error.config.__isRetryRequest = true;
            //             error.config.headers.Authorization = 'Bearer ' + res.Response.token;
            //             return axios(error.config);
            //         } else {
            //             ToLogin()
            //             return false;
            //         }
            //     });
            // } else {
                ToLogin()
            //     return false;
            // }
        } else if (Response.data.Status == 403) {
            Vue.prototype.$message({
                message: '失败！该操作无权限',
                type: 'error'
            });
            return null;
        } else if (Response.data.Status == 429) {
            Vue.prototype.$message({
                message: '刷新次数过多，请稍事休息重试！',
                type: 'error'
            });
            return null;
        } else if (Response.data.Status == 500) {
            setTimeout(function(){
                Vue.prototype.$message({
                    message: '服务器错误，请稍后重试！',
                    type: 'error'
                });
            },1000)
            return null;
        }
        // else if (Response.data.Status == 501) {
        //     setTimeout(function(){
        //         Vue.prototype.$message({
        //             message: '服务器错误501！',
        //             type: 'error'
        //         });
        //     },500)
        //     return null;
        // }
        return Response;
    },
    error => {
        // 超时请求处理
        var originalRequest = error.config;
        console.log(error.config)
        if(error.code == 'ECONNABORTED' && error.message.indexOf('timeout')!=-1 && !originalRequest._retry){

            Vue.prototype.$message({
                message: '请求超时！',
                type: 'error'
            });

            originalRequest._retry = true
            return null;
        }
        if (error.response) {
            if (error.response.status == 401) {
                // var curTime = new Date()
                // var refreshtime = new Date(Date.parse(window.localStorage.refreshtime))
                // // 在用户操作的活跃期内
                // if (window.localStorage.refreshtime && (curTime <= refreshtime)) {
                //     return  refreshToken({token: window.localStorage.Token}).then((res) => {
                //         if (res.Success) {
                //             // Vue.prototype.$message({
                //             //     message: 'refreshToken success! loading data...',
                //             //     type: 'success'
                //             // });
                //
                //             store.commit("saveToken", res.Response.token);
                //
                //             var curTime = new Date();
                //             var expiredate = new Date(curTime.setSeconds(curTime.getSeconds() + res.Response.expiresIn));
                //             store.commit("saveTokenExpire", expiredate);
                //
                //             error.config.__isRetryRequest = true;
                //             error.config.headers.Authorization = 'Bearer ' + res.Response.token;
                //             return axios(error.config);
                //         } else {
                //             // 刷新token失败 清除token信息并跳转到登录页面
                //             ToLogin()
                //             return false;   //代码显示此处共走了n(n取决于页面create接口的请求次数)遍，所以让停止再执行看看效果 date:2020-12-23
                //         }
                //     });
                // } else {
                //     // 返回 401，并且不知用户操作活跃期内 清除token信息并跳转到登录页面
                    ToLogin()
                //     return false;   //代码显示此处共走了n(n取决于页面create接口的请求次数)遍，所以让停止再执行看看效果 date:2020-12-23
                // }

            }
            // 403 无权限
            if (error.response.status == 403) {
                Vue.prototype.$message({
                    message: '失败！该操作无权限',
                    type: 'error'
                });
                return null;
            }
            // 429 ip限流
            if (error.response.status == 429) {
                Vue.prototype.$message({
                    message: '刷新次数过多，请稍事休息重试！',
                    type: 'error'
                });
                return null;
            }
            // 500
            if (error.response.status == 500) {
                setTimeout(function(){
                    Vue.prototype.$message({
                        message: '服务器错误，请稍后重试！',
                        type: 'error'
                    });
                },1000)
                return null;
            }
            // 501
            // if (error.response.status == 501) {
            //     setTimeout(function(){
            //         Vue.prototype.$message({
            //             message: '服务器错误501！',
            //             type: 'error'
            //         });
            //     },500)
            //     return null;
            // }
        }
        return ""; // 返回接口返回的错误信息
    }
);

const ToLogin = params => {
    var user = JSON.parse(
        window.localStorage.user ? window.localStorage.user : null
    );
    store.commit("saveToken", "");
    store.commit("saveTokenExpire", "");
    store.commit("saveTagsData", "");
    window.localStorage.removeItem('NavigationBar');

    var routerGo = router.currentRoute.fullPath.substring(0,6)
    if(routerGo == "/login"){
    }else{
        if(user && user.UserType == 2) {
            if(executionsNum < 1) {
                Vue.prototype.$message({
                    message: '页面已过期，请关闭当前页面！',
                    type: 'error'
                });
                executionsNum++
            }
        } else {
            window.localStorage.removeItem('user');
            router.replace({
                path: "/login",
                query: {redirect: router.currentRoute.fullPath}
            });
            window.location.reload()
        }
    }
    // router.replace({
    //     path: "/login",
    //     query: {redirect: router.currentRoute.fullPath}
    // });
    return false;  //代码显示此处共走了n(n取决于页面create接口的请求次数)遍，所以让停止再执行看看效果 date:2020-12-23
};


export const BaseApiUrl =base;

export const saveRefreshtime = params => {

    let nowtime = new Date();
    let lastRefreshtime = window.localStorage.refreshtime ? new Date(window.localStorage.refreshtime) : new Date(-1);
    let expiretime = new Date(Date.parse(window.localStorage.TokenExpire))

    let refreshCount=1;//滑动系数
    if (lastRefreshtime >= nowtime) {
        lastRefreshtime=nowtime>expiretime ? nowtime:expiretime;
        lastRefreshtime.setMinutes(lastRefreshtime.getMinutes() + refreshCount);
        window.localStorage.refreshtime = lastRefreshtime;
    }else {
        window.localStorage.refreshtime = new Date(-1);
    }
};



// 登录获取token
export const requestLogin = params => {
    // params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/login/jwttoken`, {params: params}).then(res => res.data);
};

// 加密登录获取token
export const jwttokenCrypto = params => {
    // params.ProjectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/login/jwttokenCrypto`,  params);
};

// 获取图形验证码
export const captchaImage = params => {
    // params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/captcha/captchaImage`, {params: params}).then(res => res.data);
};

//刷新token
export const refreshToken = params => {
    // params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/login/RefreshToken`, {params: params}).then(res => res.data);
};

// 社消平台跳转免登录获取token
export const ticketLogin = params => {
    // params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/login/ticketLogin`, {params: params}).then(res => res.data);
};

//首页
//获取报名人数的图表数据
export const examenrollCountGroupByDate = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/Home/ExamenrollCountGroupByDate`, {params: params});
};
//根据行政区获取报名人数的图表数据
export const examenrollCountGroupByDateByID = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/Home/ExamenrollCountGroupByDateByID`, {params: params});
};

//获取考试项目报名人数统计
export const examenrollCountGroupByProjectName = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/Home/ExamenrollCountGroupByProjectName`, {params: params});
};
//根据行政区获取考试项目报名人数统计
export const examenrollCountGroupByProjectNameByID = params => {
    params.projectId = parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/Home/ExamenrollCountGroupByProjectNameByID`, {params: params});
};

// 社会单位总数及学习中数量
export const getUnitsAndCertificatesResponse = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/Home/getUnitsAndCertificatesResponse`, {params: params});
};

// 下级社会单位总数及学习中数量
export const getUnitsAndCertificatesResponseByID = params => {
    params.projectId = parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/Home/getUnitsAndCertificatesResponseByID`, {params: params});
};

// 下级城市/地区的社会单位、持证总数、证书达标数列表
export const getProportionCertificateHolders = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/Home/GetProportionCertificateHolders`, {params: params});
};

// 下级城市/地区的持证总数统计
export const getProportionCertificateHoldersByID = params => {
    params.projectId = parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/Home/GetProportionCertificateHoldersByID`, {params: params});
};

// 社会单位持证统计分析
export const getSocialUnitCertificateHoldersStatistics = params => {
    params.projectId = parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/Home/GetSocialUnitCertificateHoldersStatistics`, {params: params});
};

// 待监管单位
export const getHomeSocialUnitStatistics = params => {
    params.projectId = parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/Home/GetHomeSocialUnitStatistics`, {params: params});
};

// 地图滑上社会单位，学习中，取证，待监管数量
export const getUnitsAndCertificatesSingleResponseByID = params => {
    params.projectId = parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/Home/getUnitsAndCertificatesSingleResponseByID`, {params: params});
};

//首页改版新接口
// 共用-获取地图上方滑上浮窗数据
export const getStudentLearnRecords = params => {
    params.projectId = parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/Home/GetStudentLearnRecords`, {params: params});
};
// 共用-获取地图地标接口
export const getJsonFile = params => {
    params.projectId = parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/GeoJson/GetJsonFile`, {params: params});
};

// 概况
// 消防安全知识知晓率排名
export const getMaterialExamPaperRightRatio = params => {
    params.projectId = parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/GeneralizationStatistics/GetMaterialExamPaperRightRatio`, {params: params});
};
// 获取待监管单位数/单位总数接口
export const getToBeSupervisedSocialUnitCount = params => {
    params.projectId = parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/GeneralizationStatistics/GetToBeSupervisedSocialUnitCount`, {params: params});
};
// 本级概况
export const getTrainingExaminationCertificateStatistics = params => {
    params.projectId = parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/GeneralizationStatistics/GetTrainingExaminationCertificateStatistics`, {params: params});
};
// 获取地图浮窗数据
export const getUnitAndSupervisionResponseStatistics = params => {
    params.projectId = parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/GeneralizationStatistics/GetUnitAndSupervisionResponseStatistics`, {params: params});
};
// 下辖概况
export const getTrainingExaminationCertificateByID = params => {
    params.projectId = parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/GeneralizationStatistics/GetTrainingExaminationCertificateByID`, {params: params});
};
// 饼状图概况
export const getAgencyInfoPass = params =>  {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/Home/getAgencyInfoPass`, {params: params});
};

// 报考情况
// 获取每日报考人数
export const getExamenrollCountGroupByDateByID = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/Home/ExamenrollCountGroupByDateByID`, {params: params});
};
// 根据岗位/行业获取报考人数/应训人数
export const getTrainingCountGroupByTradeByIDByType = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/Home/TrainingCountGroupByTradeByIDByType`, {params: params});
};
// 本级报考情况
export const getUnitAndRegistrationCountRateStatisticsByID = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/GeneralizationStatistics/GetUnitAndRegistrationCountRateStatisticsByID`, {params: params});
};
// 获取地图浮窗数据
export const getMapRegistrationRateResponseStatistics = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/GeneralizationStatistics/GetMapRegistrationRateResponseStatistics`, {params: params});
};
// 下辖报考情况
export const getUnitAndRegistrationCountRateStatistics = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/GeneralizationStatistics/GetUnitAndRegistrationCountRateStatistics`, {params: params});
};

// 参训情况
// 获取每日参训人数
export const getAttendTrainingCountGroupByDate = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/Home/AttendTrainingCountGroupByDate`, {params: params});
};
// 根据岗位/行业获取参训人数/应训人数
export const getTrainingCountGroupByTradeByID = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/Home/TrainingCountGroupByTradeByID`, {params: params});
};
// 本级参训情况
export const getCurrentLevelParticipationByID = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/Home/GetCurrentLevelParticipationByID`, {params: params});
};
// 获取地图浮窗数据
export const getTrainingMap = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/Home/GetTrainingMap`, {params: params});
};
// 下辖参训情况
export const getParticipatingJurisdictions = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/Home/GetParticipatingJurisdictions`, {params: params});
};
// 参训人数概况
export const getTrainingCountByID = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/Home/GetTrainingCountByID`, {params: params});
};
// 获取乡镇下辖单位概况明细
export const getParticipatingJurisdictionsRegion = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/Home/GetParticipatingJurisdictionsRegion`, {params: params});
};

// 持证情况
// 获取每日取证人数
export const getCertificateCountGroupByDate = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/Home/GetCertificateCountGroupByDate`, {params: params});
};
// 根据岗位/行业获取持证人数/应训人数
export const getCertificateCountGroupByTradeByID = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/Home/CertificateCountGroupByTradeByID`, {params: params});
};
// 本级持证情况
export const getCurrentLevelCertificateHoldingByID = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/Home/GetCurrentLevelCertificateHoldingByID`, {params: params});
};
// 获取地图浮窗数据
export const getMapOfCertificateHolding = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/Home/GetMapOfCertificateHolding`, {params: params});
};
// 下辖持证情况
export const getCertificateHoldingUnderTheJurisdiction = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/Home/GetCertificateHoldingUnderTheJurisdiction`, {params: params});
};
// 持证人数概况
export const getCertificateCountByID = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/Home/GetCertificateCountByID`, {params: params});
};


//系统管理
//用户管理
export const getUserListPage = params => {
    params.projectId =  parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/userInfo/getUserInfoListPage`, {params: params});
};

// 根据token获取用户详细信息
export const getUserByToken = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/userInfo/getUserInfoByToken`, {params: params}).then(res => res.data);
};

//新增用户信息
export const addUserInfo = params => {
    params.ProjectId =  parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/userInfo/addUserInfo`,  params);
};

//更新用户信息
export const updateUserInfo = params => {
    params.ProjectId =  parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/userInfo/updateUserInfo`,  params);
};

//删除用户信息
export const deleteUserInfo = params => {
    return axios.delete(`${base}/sstwebapi/userInfo/deleteUserInfo`,  {params: params});
};

//发送修改密码手机短信验证码
export const sendModifyPasswordSMSVerificationCode = params => {
    params.ProjectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/SystemManager/SendModifyPasswordSMSVerificationCode`,  params);
};

//修改用户登录密码
export const modifyUserPassword = params => {
    params.ProjectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/SystemManager/ModifyUserPassword`,  params);
};

//系统管理
//菜单权限管理

//根据用户ID获取路由树
export const getNavigationBar = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/menuPermissions/getNavigationBar`, {params: params}).then(res => res.data);
};

//根据角色ID获取路由树
export const getNavigationBarByRoleId = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/menuPermissions/getNavigationBarByRoleId`, {params: params}).then(res => res.data);
}

//获取菜单树
export const getMenuTree = params => {
    // params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/menuPermissions/getMenuTree`, {params: params});
};

//获取菜单权限树形列表
export const getMenuPermissionsTree = params => {
    // params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/menuPermissions/getMenuPermissionsTree`, {params: params});
};

//新增菜单权限信息
export const addMenuPermissionsInfo = params => {
    params.ProjectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/menuPermissions/addMenuPermissionsInfo`,  params);
};

//更新菜单权限信息
export const updateMenuPermissionsInfo = params => {
    params.ProjectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/menuPermissions/updateMenuPermissionsInfo`,  params);
};

//删除菜单权限信息
export const deleteMenuPermissionsInfo = params => {
    params.ProjectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/menuPermissions/deleteMenuPermissionsInfo`,  params);
};

//保存菜单权限分配
export const assignMenuPermissions = params => {
    params.ProjectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/menuPermissions/assignMenuPermissions`,  params);
};

//通过角色获取菜单【无权限】
export const GetAssignMenuPermissionIdByRoleId = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/menuPermissions/getAssignMenuPermissionIdByRoleId`, {params: params});
};


//系统管理
//角色管理

//根据组织结构ID获取角色信息集合
export const getRoleInfosByOrganizationInfoID = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/roleInfo/getRoleInfoByName`, {params: params});
};

//获取角色信息分页列表
export const getRoleListPage = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/roleInfo/getRoleInfoListPage`, {params: params});
};

//新增角色信息
export const addRoleInfo = params => {
    params.ProjectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/roleInfo/addRoleInfo`,  params);
};

//更新角色信息
export const updateRoleInfo = params => {
    params.ProjectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/roleInfo/updateRoleInfo`,  params);
};

//删除角色信息
export const deleteRoleInfo = params => {
    return axios.delete(`${base}/sstwebapi/roleInfo/deleteRoleInfo`,  {params: params});
};

//系统管理
// 组织结构管理

//获取组织结构树
export const getOrganizationTree = params => {
    // params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/departmentInfo/getDepartmentTree`, {params: params});
};

//新增组织结构信息
export const addOrganizationInfo = params => {
    params.ProjectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/departmentInfo/addDepartmentInfo`,  params);
};

//更新组织结构信息
export const updateOrganizationInfo = params => {
    params.ProjectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/departmentInfo/updateDepartmentInfo`,  params);
};

//删除组织结构信息
export const deleteOrganizationInfo = params => {
    return axios.delete(`${base}/sstwebapi/departmentInfo/deleteDepartmentInfo`,  {params: params});
};

//系统管理
// 接口管理

//获取接口信息树形列表
export const getApiModulesInfoTree = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/apiModulesInfo/getApiModulesInfoTree`, {params: params});
};

//获取接口菜单树
export const getApiMenuTree = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/apiModulesInfo/getApiMenuTree`, {params: params});
};

//新增接口信息
export const addApiModulesInfo = params => {
    params.ProjectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/apiModulesInfo/addApiModulesInfo`,  params);
};

//更新接口信息
export const updateApiModulesInfo = params => {
    params.ProjectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/apiModulesInfo/updateApiModulesInfo`,  params);
};

//删除接口信息
export const deleteApiModulesInfo = params => {
    return axios.delete(`${base}/sstwebapi/apiModulesInfo/deleteApiModulesInfo`,  {params: params});
};

//系统管理
// 数据权限管理

//获取全部数据权限信息列表
export const getAllDataPermissionsInfoList = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/dataPermissionsInfo/getAllDataPermissionsInfoList`, {params: params});
};

//新增数据权限信息
export const addDataPermissionsInfo = params => {
    params.ProjectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/dataPermissionsInfo/addDataPermissionsInfo`,  params);
};

//更新数据权限信息
export const updateDataPermissionsInfo = params => {
    params.ProjectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/dataPermissionsInfo/updateDataPermissionsInfo`,  params);
};

//删除数据权限信息
export const deleteDataPermissionsInfo = params => {
    return axios.delete(`${base}/sstwebapi/dataPermissionsInfo/deleteDataPermissionsInfo`,  {params: params});
};

//保存数据权限分配
export const assignDataPermissions = params => {
    params.ProjectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/dataPermissionsInfo/assignDataPermissions`,  params);
};

//通过角色ID获取数据权限菜单【无权限】
export const getAssignDataPermissionIdByRoleId = params => {
    // params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/dataPermissionsInfo/getAssignDataPermissionIdByRoleId`, {params: params});
};


//系统管理
// Task管理

export const getTaskListPage = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/TasksQz/get`, {params: params});
};
export const removeTask = params => {
    return axios.delete(`${base}/sstwebapi/TasksQz/delete`, {params: params});
};
export const editTask = params => {
    return axios.put(`${base}/sstwebapi/TasksQz/put`, params);
};
export const addTask = params => {
    params.ProjectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/TasksQz/post`, params);
};

export const startJob = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/TasksQz/StartJob`, {params: params});
};
export const stopJob = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/TasksQz/StopJob`, {params: params});
};
export const reCovery = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/TasksQz/ReCovery`, {params: params});
};

//系统管理
//操作日志

// 获取系统调用日志列表
export const getSystemLogList = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/SystemManager/GetSystemLogList`, {params: params});
};


//通用设置
//审批流程管理

//获取审批流程信息分页列表
export const getApprovalProcessListPage = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/CommonSettings/GetApprovalProcessListPage`, {params: params});
};

//新增审批流程
export const addApprovalProcess = params => {
    params.ProjectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/CommonSettings/AddApprovalProcess`,  params);
};

//更新审批流程
export const updateApprovalProcess = params => {
    params.ProjectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/CommonSettings/UpdateApprovalProcess`,  params);
};

//根据审批流程ID 获取审批流程图
export const getApprovalProcessDiagramByApprovalProcessID = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/CommonSettings/GetApprovalProcessDiagramByApprovalProcessID`, {params: params});
};

//通用设置
//项目列表
export const getProjectList = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/project/getProjectList`, {params: params});
};
//项目配置
export const getProjectSettingsItemList = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/project/getProjectSettingsItemList`, {params: params});
};
//添加项目配置
export const addProjectSettings = params => {
    params.ProjectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/project/addProjectSettings`,  params);
};


//通用设置
//单位通知模板

//获取单位通知模板分页列表
export const getNoticeTemplateListPage = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/NotificationTemplate/getNoticeTemplateListPage`, {params: params});
};

//新增单位通知模板
export const addNoticeTemplate = params => {
    params.ProjectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/NotificationTemplate/addNoticeTemplate`,  params);
};

//更新单位通知模板
export const updateNoticeTemplate = params => {
    params.ProjectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/NotificationTemplate/updateNoticeTemplate`,  params);
};

//删除单位通知模板
export const deleteNoticeTemplate = params => {
    params.ProjectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/NotificationTemplate/deleteNoticeTemplate`,  params);
};

//获取对应状态列表
export const getNoticeTemplateType = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/CommonSettings/GetNoticeTemplateType`, {params: params});
};

//通用设置
//学员通知模板

//获取学员通知模板分页列表
export const getStudentNoticeTemplateListPage = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/CommonSettings/GetStudentNoticeTemplateListPage`, {params: params});
};

//新增学员通知模板
export const addStudentNoticeTemplate = params => {
    params.ProjectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/CommonSettings/AddStudentNoticeTemplate`,  params);
};

//更新学员通知模板
export const updateStudentNoticeTemplate = params => {
    params.ProjectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/CommonSettings/UpdateStudentNoticeTemplate`,  params);
};

//删除学员通知模板
export const deleteStudentNoticeTemplate = params => {
    params.ProjectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/CommonSettings/DeleteStudentNoticeTemplate`,  params);
};


//通用设置
//展示信息

//获取展示信息分页集合
export const getShowInformationListPage = params => {
    params.projectId =  parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/showInfo/getShowInformationListPage`, {params: params});
};

//新增展示信息
export const addShowInformation = params => {
    params.ProjectId = parseInt( parseInt(window.localStorage.getItem('projectId')))
    return axios.post(`${base}/sstwebapi/showInfo/addShowInformation`,  params);
};

//更新展示信息
export const updateShowInformation = params => {
    params.ProjectId =  parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/showInfo/updateShowInformation`,  params);
};

//删除展示信息
export const deleteShowInformation = params => {
    params.ProjectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/showInfo/deleteShowInformation`,  params);
};

//顶置/取消顶置展示信息
export const setTopShowInformation = params => {
    params.ProjectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/showInfo/setTopShowInformation`,  params);
};

//通用设置
//轮播信息

//获取轮播信息分页集合
export const getCarouselInformationList = params => {
    params.projectId =  parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/carouseInfo/getCarouselInformationList`, {params: params});
};

//新增轮播信息
export const addCarouselInformation = params => {
    params.ProjectId =  parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/carouseInfo/addCarouselInformation`,  params);
};

//更新轮播信息
export const updateCarouselInformation = params => {
    params.ProjectId =  parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/carouseInfo/updateCarouselInformation`,  params);
};

//删除轮播信息
export const deleteCarouselInformation = params => {
    params.ProjectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/carouseInfo/deleteCarouselInformation`,  params);
};


//通用设置
//行政区域


//获取行政区信息列表
export const getAreaDictionaryList = params => {
    // params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/areaDictionary/getAreaDictionaryList`, {params: params});
};


export const getAreaDictionaryListWithCode = params => {
    return axios.get(`${base}/sstwebapi/areaDictionary/getAreaDictionaryListWithCode`, {params: params});
}

//新增行政区信息
export const addAreaDictionary = params => {
    params.ProjectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/CommonSettings/AddAreaDictionary`,  params);
};

//更新行政区信息
export const updateAreaDictionary = params => {
    params.ProjectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/CommonSettings/UpdateAreaDictionary`,  params);
};

//删除行政区信息
export const deleteAreaDictionary = params => {
    params.ProjectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/CommonSettings/DeleteAreaDictionary`,  params);
};

//抓取行政区信息
export const grabAreaDictionary = params => {
    params.ProjectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/CommonSettings/GrabAreaDictionary`,  params);
};


//通用设置
//数据字典信息

//获取数据字典信息列表
export const getDataDictionaryList = params => {
    params.projectId =  parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/baseDictionary/getBaseDictionaryList`, {params: params});
};

//新增数据字典信息
export const addDataDictionary = params => {
    params.ProjectId =  parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/baseDictionary/addBaseDictionary`,  params);
};

//更新数据字典信息
export const updateDataDictionary = params => {
    params.ProjectId =  parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/baseDictionary/updateBaseDictionary`,  params);
};

//删除数据字典信息
export const deleteDataDictionary = params => {
    params.ProjectId =  parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/baseDictionary/deleteBaseDictionary`,  params);
};


// 考试项目 新增数据字典
export const saveDataDictionary = params => {
    params.ProjectId =  parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/dataDictionary/saveDataDictionary`,  params);
};

//通用设置
//考试项目简介

//获取考试项目简介分页列表
export const getExamProjectExplainListPage = params => {
    params.projectId =  parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/examinationProject/getExamProjectExplainListPage`, {params: params});
};

//新增考试项目简介
export const addExamProjectExplain = params => {
    params.ProjectId =  parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/examinationProject/addExamProjectExplain`,  params);
};

//更新考试项目简介
export const updateExamProjectExplain = params => {
    params.ProjectId =  parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/examinationProject/updateExamProjectExplain`,  params);
};

//删除考试项目简介
export const deleteExamProjectExplain = params => {
    params.ProjectId =  parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/examinationProject/deleteExamProjectExplain`,  params);
};

//根据考试项目简介ID获取考试项目简介岗位行业关联息列表
export const getExamProjectExplainList = params => {
    params.projectId =  parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/examinationProject/getExamProjectExplainList`, {params: params});
};

//新增考试项目简介岗位行业关联息
export const addExamProjectExplainSTRelation = params => {
    params.ProjectId =  parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/examinationProject/addExamProjectExplainSTRelation`,  params);
};


//通用设置
//APP版本信息

//获取APP版本信息分页列表
export const getAPPVersionInfoListPage = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/appVersion/getAppVersionList`, {params: params});
};

//新增APP版本信息
export const addAPPVersionInfo = params => {
    params.ProjectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/appVersion/addAppVersion`,  params);
};

export const addAPPVersionInfoLibrary1 = `${base}/sstwebapi/appVersion/addAppVersion`;

//更新APP版本信息
export const updateAPPVersionInfo = params => {
    params.ProjectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/appVersion/updateAppVersion`,  params);
};

export const updateAPPVersionInfoLibrary1 = `${base}/sstwebapi/CommonSettings/UpdateAPPVersionInfo`

//删除APP版本信息
export const deleteAPPVersionInfo = params => {
    params.ProjectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/appVersion/deleteAppVersion`,  params);
};

//通用设置
//其他设置

//获取其他设置
export const getCommonSetting = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/dataDictionary/getCommonSetting`, {params: params});
};

//获取允许删除单位人员数据
export const getDeletedStaffsAuthoritySetting = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/dataDictionary/getByNamespace`, {params: params});
};

//保存其他设置
export const setCommonSetting = params => {
    params.ProjectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/CommonSettings/setCommonSetting`,  params);
};

//保存其他设置
export const updateDeletedStaffsAuthoritySetting = params => {
    params.ProjectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/CommonSettings/updateDeletedStaffsAuthoritySetting`,  params);
};

//通用设置
//单位填报标准设置

//获取单位填报标准设置
export const getUnitFillingSetting = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/CommonSettings/getUnitFillingSetting`, {params: params});
};

//保存单位填报标准设置
export const updateUnitFillCommonSetting = params => {
    params.ProjectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/CommonSettings/updateUnitFillCommonSetting`,  params);
};

//通用设置
//简报数据设置

//获取简报数据设置
export const getReportDataSetting = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/CommonSettings/getReportDataSetting`, {params: params});
};

//查看简报数据设置
export const getReportDataSettingByBatch = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/CommonSettings/getReportDataSettingByBatch`, {params: params});
};

//新增/编辑简报数据设置
export const saveReportDataSettingByBatch = params => {
    params.ProjectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/CommonSettings/saveReportDataSettingByBatch`,  params);
};

//删除简报数据设置
export const deletedReportDataSettingByBatch = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/CommonSettings/deletedReportDataSettingByBatch`, {params: params});
};

//启用/禁用简报数据设置
export const isEnableReportDataSettingForBatch = params => {
    params.ProjectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/CommonSettings/isEnableReportDataSettingForBatch`,  params);
};

//主数据管理
//试题管理

//获取试题信息分页列表
export const getExaminationQuestionsListPage = params => {
    params.projectId =  parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/examinationQuestions/getExaminationQuestionsListPage`, {params: params});
};

//根据教材ID获取试题分页集合
export const getExaminationQuestionsByTMIDListPageAsync = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/examinationQuestions/getExaminationQuestionsByTMIDListPageAsync`, {params: params});
};

//新增试题信息
export const addExaminationQuestions = params => {
    params.ProjectId =  parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/examinationQuestions/addExaminationQuestions`,  params);
};

//编辑试题信息
export const updateExaminationQuestions = params => {
    params.ProjectId =  parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/examinationQuestions/updateExaminationQuestions`,  params);
};

//删除试题信息
export const deleteExaminationQuestions = params => {
    params.ProjectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/examinationQuestions/deleteExaminationQuestions`,  params);
};

//公开/私有试题信息
export const shareOrPrivateExaminationQuestions = params => {
    params.ProjectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/examinationQuestions/shareOrPrivateExaminationQuestions`,  params);
};

//建立教材与试题关联关系
export const relationTeachingExamination = params => {
    params.ProjectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/teachingMaterialInfo/relationTeachingExamination`,  params);
};

//取消教材与试题关联关系
export const delRelationTeachingExamination = params => {
    params.ProjectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/teachingMaterialInfo/delRelationTeachingExamination`,  params);
};

//转为字典试题审批
export const convertDictionaryExaminationQuestionsApproval = params => {
    params.ProjectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/WorkFlow/ConvertDictionaryExaminationQuestionsApproval`,  params);
};

//变更字典试题审批
export const changeDictionaryExaminationQuestionsApproval = params => {
    params.ProjectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/WorkFlow/ChangeDictionaryExaminationQuestionsApproval`,  params);
};

// 批量导入试题
// export const importExaminationQuestions = `${base}/sstwebapi/examinationQuestions/importExaminationQuestions` + `/` + parseInt(window.localStorage.getItem('projectId'))

//主数据管理
//教材管理

//获取教材树
export const getTeachingMaterialTree = params => {
    params.projectId =  parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/teachingMaterialInfo/getTeachingMaterialTree`, {params: params});
};

//获取两级考试卷纸教材信息树
export const getExamPaperTeachingMaterialTree = params => {
    params.projectId =  parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/examinationPaper/getExamPaperTeachingMaterialTree`, {params: params});
};


//新增教材信息
export const addTeachingMaterialInfo = params => {
    params.ProjectId =  parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/teachingMaterialInfo/addTeachingMaterialInfo`,  params);
};

//更新教材信息
export const updateTeachingMaterialInfo = params => {
    params.ProjectId =  parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/teachingMaterialInfo/updateTeachingMaterialInfo`,  params);
};

//删除教材信息
export const deleteTeachingMaterialInfo = params => {
    params.ProjectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/teachingMaterialInfo/deleteTeachingMaterialInfo`,  params);
};



//工作流管理

//审批
export const approve = params => {
    params.ProjectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/WorkFlow/Approve`,  params);
};

//获取待处理信息分页列表
export const getPendingIssuesListPage = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/WorkFlow/GetPendingIssuesListPage`, {params: params});
};

//获取已处理信息分页列表
export const getProcessedIssuesListPage = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/WorkFlow/GetProcessedIssuesListPage`, {params: params});
};

//获取已发起信息分页列表
export const getLaunchIssuesListPage = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/WorkFlow/GetLaunchIssuesListPage`, {params: params});
};

//获取全部事项分页列表
export const getAllIssuesListPage = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/WorkFlow/GetAllIssuesListPage`, {params: params});
};

//根据审批记录ID获取试题审批记录详情
export const getExaminationQuestionsApprovalRecordByID = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/WorkFlow/GetExaminationQuestionsApprovalRecordByID`, {params: params});
};

//根据审批记录ID获取发布考试项目详情
export const getPublishExamProjectInfoByID = params => {
    params.projectId =  parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/WorkFlow/GetPublishExamProjectInfoById`, {params: params});
};


//考试管理
//考试项目

//获取考试项目分页列表
export const getExamProjectInfoListPage = params => {
    params.projectId =  parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/examinationProject/getExamProjectInfoListPage`, {params: params});
};

//新增考试项目
export const addExamProjectInfo = params => {
    params.ProjectId =  parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/examinationProject/addExamProjectInfo`,  params);
};

//编辑考试项目
export const updateExamProjectInfo = params => {
    params.ProjectId =  parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/examinationProject/updateExamProjectInfo`,  params);
};

//删除考试项目
export const deleteExamProjectInfo = params => {
    params.ProjectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/examinationProject/deleteExamProjectInfo`,  params);
};

//发布考试项目
export const publishExamProjectInfo = params => {
    params.ProjectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/examinationProject/publishExamProjectInfo`,  params);
};

//取消发布考试项目
export const cancelExamProjectInfo = params => {
    params.ProjectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/examinationProject/handleCancelExamProject`,  params);
};


//考试管理
//试卷管理

//获取考试试卷分页列表
export const getExaminationPaperListPage = params => {
    params.projectId =  parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/examinationPaper/getExaminationPaperListPage`, {params: params});
};

//根据试卷ID获取考试试卷信息
export const getExaminationPaperByID = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/examinationPaper/getExaminationPaperById`, {params: params});
};

//新增考试试卷
export const addExaminationPaper = params => {
    params.ProjectId =  parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/examinationPaper/addExaminationPaper`,  params);
};

//编辑考试试卷
export const updateExaminationPaper = params => {
    params.ProjectId =  parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/examinationPaper/updateExaminationPaper`,  params);
};

//删除考试试卷
export const deleteExaminationPaper = params => {
    params.ProjectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/examinationPaper/deleteExaminationPaper`,  params);
};

//启用/禁用考试试卷
export const enableOrDisableExaminationPaper = params => {
    params.ProjectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/examinationPaper/enableOrDisableExaminationPaper`,  params);
};

//获取行业类型
export const getExamPaperInfoTradeTypeList = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/ExamManage/GetExamPaperInfoTradeTypeList`, {params: params});
};


//证书管理
//证书设计

//获取证书设计分页列表
export const getCertificateInfoListPage = params => {
    // params.projectId =  parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/certificateDesign/getCertificateInfoListPage`, {params: params});
};

//新增证书设计
export const addCertificateInfo = params => {
    params.ProjectId =  parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/certificateDesign/addCertificateInfo`,  params);
};

//编辑证书设计
export const updateCertificateInfo = params => {
    params.ProjectId =  parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/certificateDesign/updateCertificateInfo`,  params);
};

//删除证书设计
export const deleteCertificateInfo = params => {
    params.ProjectId =  parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/certificateDesign/deleteCertificateInfo`,  params);
};

// 历史登记单位
export const getCertificateRegisterByIDList = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/CertificateManager/GetCertificateRegisterByIDList`, {params: params});
};

//证书管理
//已发证查询

//获取已发证分页列表
export const getIssuedCertificateInfoListPage = params => {
    params.projectId =  parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/statisticCert/getIssuedCertificateInfoListPage`, {params: params});
};

//撤销证书
export const cancelIssuedCertificateInfo = params => {
    params.ProjectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/certificate/cancelIssuedCertificateInfo`,  params);
};

//证书管理
//发证审核

//获取发证审核分页列表
export const getCertificationReviewListPage = params => {
    params.projectId =  parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/certificate/getCertificationReviewListPage`, {params: params});
};

//发证审核通过/驳回
export const examinationReview = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/certificate/examinationReview`, {params: params});
};

//发证批量审核通过/驳回
export const examinationReviewBatch = params => {
    params.ProjectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/certificate/examinationReviewBatch`,  params);
};

//获取抓拍照片列表
export const getTakePhotosList = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/statisticPaper/getTakePhotosList`, {params: params});
};
//获取人声监测列表
export const getRecordingMonitoringList = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/statisticPaper/getRecordingMonitoringList`, {params: params});
};

//学员考生管理
//账号管理

//获取学员考生账号分页列表
export const getStudentExamineeAccountListPage = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/statisticStudent/getStudentAccountListPage`, {params: params});
};

//启用/禁用学员考生账号
export const enableOrDisableStudentExamineeAccount = params => {
    params.ProjectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/studentAccount/enableOrDisableStudentExamineeAccount`,  params);
};

//学员考生管理
//档案管理

//获取学员考生档案分页列表
export const getStudentExamineeIdentityListPage = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/statisticStudent/getExamEnrollListPage`, {params: params});
};

//修改学员考生档案信息
export const modifyStudentExamineeIdentity = params => {
    params.ProjectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/StudentExaminee/ModifyStudentExamineeIdentity`,  params);
};

//获取学员流向分页列表
export const getStudentLearnRecordPageList = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/StudentExaminee/getStudentLearnRecordPageList`, {params: params});
};


//学员考生管理
//考生报名信息

//获取学员考生报名信息分页列表
//24-02-05 从业人员管理-报考情况明细页面换下面新接口
export const getExamEnrollInfoListPage = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/StudentExaminee/GetExamEnrollInfoListPage`, {params: params});
};
export const getStudentExamEnrollListPage = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/StudentExaminee/getStudentExamEnrollListPage`, {params: params});
};

//新增学员考生报名信息
export const addStudentExamEnrollInfo = params => {
    params.ProjectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/StudentExaminee/AddStudentExamEnrollInfo`,  params);
};
export const submitStudentInformationByManger = params => {
    params.ProjectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/StudentExaminee/submitStudentInformationByManger`,  params);
};

//导入考生报名信息
export const importStudentExamEnrollInfo = params => {
    params.ProjectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/StudentExaminee/ImportStudentExamEnrollInfo`,  params);
};

//获取学员考生账号考试记录分页列表
export const getStudentExamineeAccountExamListPage = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/studentManage/getStudentExamineeAccountExamListPage`, {params: params});
};

//获取学员考生账号学习记录分页列表
export const getStudentExamineeAccountStudyListPage = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/studentManage/getStudentExamineeAccountStudyListPage`, {params: params});
};

//获取学员考生账号学习记录总时长
export const getLearnRecordByEnroll = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/studentManage/getLearnRecordByEnroll`, {params: params});
};

//获取学员考生账号练习记录分页列表
export const getStudentExamineeAccountTrainListPage = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/studentManage/getStudentExamineeAccountTrainListPage`, {params: params});
};

// 导入考生报名信息
export const importStudentExamEnrollInfoUrl = `${base}/sstwebapi/StudentExaminee/ImportStudentExamEnrollInfo`

//导出学员考生报名信息列表
export const exportExamEnrollInfo = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/StudentExaminee/ExportExamEnrollInfo`, {params: params});
};

//考生报名列表筛选考生后，可对考生生成批次
export const generateBatch = params => {
    params.ProjectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/StudentExaminee/GenerateBatch`,  params);
};

//加入批次
export const addBatch = params => {
    params.ProjectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/StudentExaminee/AddBatch`,  params);
};

// 修改允许考试
export const updateIsExemptTrain = params => {
    params.ProjectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/ExamManage/UpdateIsExemptTrain`,  params);
};

// 发送短信
export const candidateSendsSMS = params => {
    params.ProjectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/ExamManage/CandidateSendsSMS`,  params);
};

// 免培考试批次管理
//获取考生报名批次列表
export const getExamBatchPopulationList = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/ExamManage/GetExamBatchPopulationList`, {params: params});
};

//删除考试批次
export const deleteExaminationBatch = params => {
    params.ProjectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/ExamManage/DeleteExaminationBatch`,  params);
};

// 免培考试批次管理
// 考试申请审核

// 获取发送短信申请记录列表
export const getExamBatchMsgApplyRecordList = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/ExamManage/getExamBatchMsgApplyRecordList`, {params: params});
};

// 获取发送短信名单详情列表
export const getExamBatchMsgDetailRecordList = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/ExamManage/getExamBatchMsgDetailRecordList`, {params: params});
};

// 申请组织考试发送短信审核
export const reviewBatchMsg = params => {
    params.ProjectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/ExamManage/reviewBatchMsg`,  params);
};

// 申请组织考试发送短信审核
export const checkMsg = params => {
    params.ProjectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/ExamManage/Msg`,  params);
};

//学员考生管理
//考生汇总信息

//学员考生汇总查询分页列表
export const getStudentExamineeAggregateListPage = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/StudentExaminee/GetStudentExamineeAggregateListPage`, {params: params});
};

//行政区下属区域学员考生汇总查询列表
export const getStudentExamineeAggregateList = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/StudentExaminee/GetStudentExamineeAggregateList`, {params: params});
};

//学员考生管理
//成绩记录

//获取成绩记录分页列表
export const getStudentAchievementListPage = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/statisticPaper/getStudentAchievementListPage`, {params: params});
};

//删除考试记录
export const deleteStudentExamineePaperById = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/StudentExaminee/deleteStudentExamineePaperById`, {params: params});
};

//从业人员管理
//报考资料审核

//获取报考资料审核分页列表
export const getStudentApprovePageList = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/studentExam/getStudentApprovePageList`, {params: params});
};

//报考资料审核通过
export const reviewStudentApprove = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/studentExam/reviewStudentApprove`, {params: params});
};

//报考资料审核驳回
export const updateStudentApprove = params => {
    params.ProjectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/studentExam/updateStudentApprove`,  params);
};

//报考资料删除
export const deleteStudentApprove = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/studentExam/deleteStudentApprove`, {params: params});
};

//学员考生管理-培训机构专栏

// 培训机构考生报名信息
//获取培训机构考生报名信息
export const getExamEnrollInfoListPageNew = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/statisticStudent/getStudentExamEnrollListPage`, {params: params});
};

//根据手机号获取该手机号是否是应训人员
export const getCheckIsSocialUnitStaff = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/StudentExaminee/CheckIsSocialUnitStaff`, {params: params});
};

// 培训机构考生汇总信息
//获取培训机构考生汇总信息
export const getStudentExamineeAggregateListPageNew = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/StudentExaminee/GetStudentExamineeAggregateListPageNew`, {params: params});
};

// 培训机构成绩记录
//获取培训机构成绩记录
export const getStudentAchievementListPageNew = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/CrowdManagement/GetStudentAchievementListPageNew`, {params: params});
};

//上传临时文件
export const uploadTempFile = `${base}/sstwebapi/image/uploadTempFile`

//图片管理

//上传图片
export const uploadTempPic = `${base}/sstwebapi/image/uploadTempPic`
// 上传图片路径
export const uploadImagesUrl = `${base}/sstwebapi/image/uploadTempPic`

// 上传图片路径-入参加token
export const uploadImgByEditor = `${base}/sstwebapi/image/uploadImgByEditor`

//通知管理
//号码库

//获取号码库信息分页列表
export const getPhoneNumberLibraryListPage = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/NoticeManage/GetPhoneNumberLibraryListPage`, {params: params});
};

//新增号码库信息
export const addPhoneNumberLibrary = params => {
    params.ProjectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/NoticeManage/AddPhoneNumberLibrary`,  params);
};

export const addPhoneNumberLibrary1 = `${base}/sstwebapi/NoticeManage/AddPhoneNumberLibrary`

//编辑号码库信息
export const updatePhoneNumberLibrary = params => {
    params.ProjectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/NoticeManage/UpdatePhoneNumberLibrary`,  params);
};

export const updatePhoneNumberLibrary1 = `${base}/sstwebapi/NoticeManage/UpdatePhoneNumberLibrary`

//删除号码库信息
export const deletePhoneNumberLibrary = params => {
    params.ProjectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/NoticeManage/DeletePhoneNumberLibrary`,  params);
};

//获取号码库信息详情分页列表
export const getPhoneNumberLibraryDetailListPage = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/NoticeManage/GetPhoneNumberLibraryDetailListPage`, {params: params});
};

//通知管理
//通知发送

//发送通知信息
export const addSendNotification = params => {
    params.ProjectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/NoticeManage/AddSendNotification`,  params);
};

//通知管理
//通知记录

//获取通知记录分页列表
export const getNoticeRecordListPage = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/NoticeManage/GetNoticeRecordListPage`, {params: params});
};

//获取通知记录详情分页列表
export const getNoticeRecordDetailListPage = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/NoticeManage/GetNoticeRecordDetailListPage`, {params: params});
};


//持证监管
//社会单位明细

//获取社会单位信息分页列表
export const getSocialUnitListPage = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/statisticCert/getSocialUnitListPage`, {params: params});
};
//获取社会单位信息分页列表-信息完整
export const getUnFinishedSocialUnitListPage = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/LicensedSupervision/getUnFinishedSocialUnitListPage`, {params: params});
};
export const getSociUnitById = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/LicensedSupervision/GetSociUnitById`, {params: params});
};

//获取社会单位信息分页列表1
export const getSocialUnitDataListPage = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/LicensedSupervision/GetSocialUnitDataListPage`, {params: params});
};


//根据社会单位ID获取单位证书登记详情分页列表
export const getCertificateRegisterBySocialUnitIDListPage = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/LicensedSupervision/GetCertificateRegisterBySocialUnitIDListPage`, {params: params});
};

//获取社会单位导入模板地址
export const getImportSocialUnitTemplateUrl = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/LicensedSupervision/GetImportSocialUnitTemplateUrl`, {params: params});
};

let projectId = parseInt(window.localStorage.getItem('projectId'))

// 导入社会单位
export const importSocialUnit = `${base}/sstwebapi/socialUnit/importSocialUnit/`+projectId
export const importSocialUnitStaff = `${base}/sstwebapi/socialUnit/importSocialUnitStaff/`+projectId

//创建人群
export const addSocialGroup = params => {
    params.ProjectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/CrowdManagement/addSocialGroup`, params);
};

//获取单位人员列表
export const getSocialUnitStaffList = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/socialStaff/getSocialUnitStaffList`, {params: params});
};

// 添加/编辑社会单位
export const saveSocialUnit = params => {
    params.ProjectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/socialUnit/saveSocialUnit`,  params);
};

// 删除社会单位明细
export const deleteSocialUnitChaos = params => {
    params.ProjectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/LicensedSupervision/deleteSocialUnitChaos`,  params);
};

//同步社消平台
export const updateSxptDataService = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/LicensedSupervision/updateSxptDataService`, {params: params});
};

//查看社消平台名单
export const getSxptExamTrainDetail = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/LicensedSupervision/getSxptExamTrainDetail`, {params: params});
};

//删除社消平台名单
export const deletedDcSxptExamTrainDetail = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/LicensedSupervision/deletedDcSxptExamTrainDetail`, {params: params});
};

// 持证监管
//大杂烩单位明细

// 获取社会单位大杂烩信息分页列表
export const getSocialUnitListPageChaos = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/LicensedSupervision/GetSocialUnitListPageChaos`, {params: params});
};

// 获取社会单位大杂烩职员列表详情
export const getSocialUnitStaffListChaos = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/LicensedSupervision/GetSocialUnitStaffListChaos`, {params: params});
};

//大杂烩应训人员
// 获取大杂烩应训人员分页列表
export const getSocialUnitStaffPageListChaos = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/statisticUnit/getSocialUnitStaffPageList`, {params: params});
};

// 导出大杂烩应训人员分页列表
export const exportSocialUnitStaffPageListChaos = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/LicensedSupervision/ExportSocialUnitStaffPageListChaos`, {params: params});
};

// 新增/编辑大杂烩应训人员
export const saveSocialUnitStaffChaos = params => {
    params.ProjectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/LicensedSupervision/SaveSocialUnitStaffChaos`,  params);
};

// 删除大杂烩应训人员
export const deleteSocialUnitStaffChaos = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/LicensedSupervision/DeleteSocialUnitStaffChaos`, {params: params});
};

// 新旧应训人员融合
export const updateSocialUnitStaffByChaos = params => {
    params.ProjectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/LicensedSupervision/UpdateSocialUnitStaffByChaos`,  params);
};

// 新旧单位融合
export const updateSocialUnitByChaos = params => {
    params.ProjectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/LicensedSupervision/UpdateSocialUnitByChaos`,  params);
};

// 随机抽查下载excel
export const processAndReturnExcel = params => {
    params.ProjectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/StudentExaminee/processAndReturnExcel`,  params);
};

// 随机抽查上传excel
export const uploadProcessAndReturnExcel = `${base}/sstwebapi/StudentExaminee/processAndReturnExcel`


//持证监管
//待通知名单

//获取已通知名单列表
export const getNoticeBillList = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/LicensedSupervision/GetNoticeBillList`, {params: params});
};

//获取待通知名单数量
export const getToBeNoticeBillCount = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/LicensedSupervision/GetToBeNoticeBillCount`, {params: params});
};

//生成最新通知名单
export const createNoticeBill = params => {
    params.ProjectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/LicensedSupervision/CreateNoticeBill`,  params);
};


//持证监管
//待处罚名单

//获取待处罚单位信息分页列表
export const getToBePunishSocialUnitListPage = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/LicensedSupervision/GetToBePunishSocialUnitListPage`, {params: params});
};

//处理社会单位
export const handleSocialUnit = params => {
    params.ProjectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/LicensedSupervision/HandleSocialUnit`,  params);
};

//持证监管
//人群管理

//获取人群名单列表
export const getSocialGroupListPage = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/CrowdManagement/GetSocialGroupListPage`, {params: params});
};

//获取人群名单列表只返回id和name
export const getSocialGroupName = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/CrowdManagement/getSocialGroupName`, {params: params});
};

//查询单位人群名单
export const listForSocialUnit = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/NoticeSms/listForSocialUnit`, {params: params});
};

//查询应训人员人群名单
export const listForSocialUnitStaff = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/NoticeSms/listForSocialUnitStaff`, {params: params});
};

//查询短信模板
export const selectAllSmsTemplateName = params => {
    // params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/NoticeSms/selectAllSmsTemplateName`, {params: params});
};

//获取人群名单列表
export const getSocialGroupDetailListPage = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/CrowdManagement/GetSocialGroupDetailListPage`, {params: params});
};

//删除人群管理
export const deleteSocialGroup = params => {
    params.ProjectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/CrowdManagement/DeleteSocialGroup`,  params);
};

//获取应训人员名单列表
export const getSocialGroupDetailListPageForStaff = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/CrowdManagement/GetSocialGroupDetailListPageForStaff`, {params: params});
};

//持证监管
//通知管理

//获取通知管理列表
export const listForNotificationManagement = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/NoticeSms/listForNotificationManagement`, {params: params});
};

//获取发送短信成功/失败人数
export const listForSendSMS = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/NoticeSms/listForSendSMS`, {params: params});
};

//审核通过
export const approved = params => {
    params.ProjectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/NoticeSms/approved`,  params);
};

//审核驳回
export const reviewFailed = params => {
    params.ProjectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/NoticeSms/reviewFailed`,  params);
};

//新建短信群发
export const applyForSendSMS = params => {
    params.ProjectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/NoticeSms/applyForSendSMS`,  params);
};

//重新发送短信
export const resendSMS = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/NoticeSms/resendSMS`, {params: params});
};

//失败短信重发
export const failedSendSMSResend = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/NoticeSms/failedSendSMSResend`, {params: params});
};

//持证监管
//应训人数

//获取应训人数分页列表
export const getSocialUnitStaffPageList = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/statisticUnit/getSocialUnitStaffPageList`, {params: params});
};

//新增/编辑应训人数
export const saveSocialUnitStaff = params => {
    params.ProjectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/LicensedSupervision/SaveSocialUnitStaff`,  params);
};

//删除应训人数
export const deleteSocialUnitStaff = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/LicensedSupervision/DeleteSocialUnitStaff`, {params: params});
};

//社会单位汇总

//社会单位汇总查询分页列表
export const getSocialUnitAggregateListPage = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/statisticCert/getSocialUnitAggregateListPage`, {params: params});
};

//社会单位汇总统计
export const getSocialUnitAggregateTotal = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/LicensedSupervision/GetSocialUnitAggregateTotal`, {params: params});
};

// 持证监管
// 单位应训人员

// 获取单位应训人员列表
export const getSocialUnitChaosForMarketing = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/CertificateManager/getSocialUnitChaosForMarketing`, {params: params});
};

// 获取填报执行人列表
export const getExecutorByUnitId = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/CertificateManager/getExecutorByUnitId`, {params: params});
};

// 获取本单位人员列表
export const getStaffsByUnitId = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/CertificateManager/getStaffsByUnitId`, {params: params});
};

// 获取学习时长大于0人数
export const getLearnStaffsByUnitId = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/CertificateManager/getLearnStaffsByUnitId`, {params: params});
};

//培训机构管理
//培训机构信息审核

//获取培训机构分页列表
export const getAgencyInfoListPage = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/agencyInfo/getAgencyInfoListPage`, {params: params});
};

//培训机构通过审核
export const approveAgencyInfo = params => {
    params.ProjectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/agencyInfo/approveAgencyInfo`,  params);
};

//关联品牌
export const relationBrand = params => {
    params.ProjectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/AgencyManager/RelationBrand`,  params);
};

//删除培训机构
export const deleteAgencyInfo = params => {
    params.ProjectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/agencyInfo/deleteAgencyInfo`,  params);
};


//培训机构管理
//品牌管理

//获取品牌列表列表
export const getBrandListPage = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/Brand/GetBrandListPage`, {params: params});
};

//新增品牌
export const addBrand = params => {
    params.ProjectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/Brand/AddBrand`,  params);
};

//更新品牌
export const updateBrand = params => {
    params.ProjectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/Brand/UpdateBrand`,  params);
};

//删除品牌
export const deleteBrand = params => {
    params.ProjectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/Brand/DeleteBrand`,  params);
};

//培训机构管理
//变更记录

//获取变更记录列表
export const getLearnChangeListPage = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/AgencyManager/getLearnChangeListPage`, {params: params});
};

//培训机构
//培训机构信息登记

//新增培训机构
export const addAgencyInfo = params => {
    params.ProjectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/AgencyManager/AddAgencyInfo`,  params);
};

//编辑培训机构
export const updateAgencyInfo = params => {
    params.ProjectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/AgencyManager/UpdateAgencyInfo`,  params);
};

//获取培训机构详情
export const getAgencyInfo = params => {
    // params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/agencyInfo/getAgencyInfo`, {params: params});
};



//培训机构
//学习记录管理

//获取学习记录分页列表
export const getStudentLearnRecordListPage = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/StatisticStudent/getStudentLearnRecordListPage`, {params: params});
};

//获取学习纪录导入模板地址
export const getImportStudentLearnRecordTemplateUrl = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/AgencyManager/GetImportStudentLearnRecordTemplateUrl`, {params: params});
};


// 导入学习纪录
export const importStudentLearnRecord = `${base}/sstwebapi/AgencyManager/ImportStudentLearnRecord`

//问答管理
//问答类型

//获取问答类型集合
export const getQuestionAnswerTypeList = params => {
    // params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/questionAnswer/getQuestionAnswerTypeList`, {params: params});
};

//新增问答类型
export const addQuestionAnswerType = params => {
    params.ProjectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/questionAnswer/addQuestionAnswerType`,  params);
};

//更新问答类型
export const updateQuestionAnswerType = params => {
    params.ProjectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/questionAnswer/updateQuestionAnswerType`,  params);
};

//删除问答类型
export const deleteQuestionAnswerType = params => {
    params.ProjectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/questionAnswer/deleteQuestionAnswerType`,  params);
};


//问答管理
//问答信息


//获取问答信息分页列表
export const getQuestionAnswerInfoListPage = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/questionAnswer/getQuestionAnswerInfoListPage`, {params: params});
};

//新增问答信息
export const addQuestionAnswerInfo = params => {
    params.ProjectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/questionAnswer/addQuestionAnswerInfo`,  params);
};

//更新问答信息
export const updateQuestionAnswerInfo = params => {
    params.ProjectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/questionAnswer/updateQuestionAnswerInfo`,  params);
};

//删除问答信息
export const deleteQuestionAnswerInfo = params => {
    params.ProjectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/questionAnswer/deleteQuestionAnswerInfo`,  params);
};

//推荐/取消推荐问答信息
export const recommendQuestionAnswerInfo = params => {
    params.ProjectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/questionAnswer/recommendQuestionAnswerInfo`,  params);
};


//问答管理
//在线留言

//获取在线留言分页列表
export const getOnlineMessageListPage = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/studentMessage/getOnlineMessageListPage`, {params: params});
};


//删除在线留言
export const deleteOnlineMessage = params => {
    params.ProjectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/studentMessage/deleteOnlineMessage`,  params);
};

//处理在线留言
export const handleOnlineMessage = params => {
    params.ProjectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/studentMessage/handleOnlineMessage`,  params);
};

//修改在线留言备注
export const editOnlineMessageRemark = params => {
    params.ProjectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/studentMessage/editOnlineMessageRemark`,  params);
};

//h5页面
//h5页面单位信息收集

// 发送验证码接口
// export const accessingPromotionalPages = params => {
//     return axios.post(`${base}/sstwebapi/NoticeManage/AccessingPromotionalPages`,  params);
// };
export const accessingPromotionalPages = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/NoticeManage/AccessingPromotionalPages`, {params: params});
};

// 学员登录
export const studentCodeToken = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/login/StudentCodeToken`, {params: params});
};

// 发送验证码接口
export const sendSMSVerificationCode = params => {
    params.ProjectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/StudentExaminee/SendSMSVerificationCode`,  params);
};

// 通过图形验证码发送短信验证码接口
export const sendSMSVerificationCodeWithVerify = params => {
    params.ProjectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/StudentExaminee/SendSMSVerificationCodeWithVerify`,  params);
};

// 获取学员社会单位联系人状态
export const getStudentSocialUnitStatus = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/StudentExaminee/GetStudentSocialUnitStatus`, {params: params});
};

// 获取岗位行业信息列表
export const getStationTradeList = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/StudentExaminee/GetStationTradeList`, {params: params});
};

// 批量新增单位消防人员
export const addSocialUnitStaff = params => {
    params.ProjectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/StudentExaminee/AddSocialUnitStaff`,  params);
};

// 批量新增大型商业综合体关联单位
export const addChildSocialUnit = params => {
    params.ProjectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/StudentExaminee/AddChildSocialUnit`,  params);
};

// 获取社会单位消防人员接口
export const getStudentSocialUnitStaffList = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/StudentExaminee/GetStudentSocialUnitStaffList`, {params: params});
};

// 获取单位是否大型商业综合体及单位列表接口
export const getStudentChildSocialUnitList = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/StudentExaminee/GetStudentChildSocialUnitList`, {params: params});
};

// 获取省市区乡镇接口
export const getAreaDictionaryListNToken = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/StudentExaminee/GetAreaDictionaryList`, {params: params});
};

// 查询公司列表接口
export const getSocialUnitListByKeyWord = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/socialUnit/getSocialUnitListByKeyWord`, {params: params});
};
// 查询公司列表接口
export const getSocialUnitChaosListByKeyWord = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/StudentExaminee/GetSocialUnitChaosListByKeyWord`, {params: params});
};

// 添加公司和应训人员
export const addSocialUnitAndStaff = params => {
    params.ProjectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/StudentExaminee/AddSocialUnitAndStaff`,  params);
};

// 根据公司id获取公司和应训人员信息
export const getStudentSocialUnitById = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/StudentExaminee/GetStudentSocialUnitById`, {params: params});
};
// 根据公司id获取公司和应训人员信息
export const getStudentSocialUnitChaosById = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/StudentExaminee/GetStudentSocialUnitChaosById`, {params: params});
};

// 上传excel分析人员的接口
export const analyseSocialUnitStaffChaos = `${base}/sstwebapi/StudentExaminee/AnalyseSocialUnitStaffChaos`

// 首页数据详情
// 获取培训机构概况分页列表
export const getAgencyInfoBriefListPage = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/AgencyManager/GetAgencyInfoBriefListPage`, {params: params});
};

// 获取参训人员分页列表
export const getLearnStudentInfoListPage = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/StudentExaminee/GetLearnStudentInfoListPage`, {params: params});
};

// 获取最新版app包
export const getNewAPPVersionInfo = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/StudentExaminee/GetNewAPPVersionInfo`, {params: params});
};

// 数据统计分析
// 统计分析
// 获取累计数量数据
export const getStatisticalOfAccumulatedQuantity = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/GeneralizationStatistics/GetStatisticalOfAccumulatedQuantity`, {params: params});
};

// 获取趋势分析数据
export const trendAnalysisData = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/GeneralizationStatistics/TrendAnalysisData`, {params: params});
};
export const trendAnalysisDataNew = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/GeneralizationStatistics/TrendAnalysisDataNew`, {params: params});
};

// 获取趋势分析柱状图
export const trendAnalysisDataOfCertificateRate = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/GeneralizationStatistics/TrendAnalysisDataOfCertificateRate`, {params: params});
};

// 获取趋势分析列表注册数量
export const getStudentAccountDetailByDate = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/GeneralizationStatistics/getStudentAccountDetailByDate`, {params: params});
};

// 获取趋势分析列表报考数量
export const getStudentExamEnrollDetailByDate = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/GeneralizationStatistics/getStudentExamEnrollDetailByDate`, {params: params});
};

// 获取趋势分析列表持证数量
export const getStudentCertificateDetailByDate = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/GeneralizationStatistics/getStudentCertificateDetailByDate`, {params: params});
};

// 考试情况分析
// 获取考试情况分析详情
export const getExaminationSituationAnalysisTotal = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/GeneralizationStatistics/getExaminationSituationAnalysisTotal`, {params: params});
};

// 获取考试情况分析列表
export const getExaminationSituationAnalysisDetail = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/GeneralizationStatistics/getExaminationSituationAnalysisDetail`, {params: params});
};

// 考核简报
// 获取周报月报概况数据分页列表
export const getAssessmentReport = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/GeneralizationStatistics/getAssessmentReport`, {params: params});
};

// 下载简报
export const downloadAssemenetReport = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/GeneralizationStatistics/downloadAssemenetReport`, {params: params,responseType: 'arraybuffer'});
};

// 通知效果跟踪
// 新名单
// 获取可发短信总人数/待人工清洗数据
export const getNumOfDirResource = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/NoticeSms/getNumOfDirResource`, {params: params});
};

// 获取发送短信批次数据
export const getBatchOfStatus = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/NoticeSms/getBatchOfStatus`, {params: params});
};

// 获取新名单/未访问H5页面发送通知概况预览
export const getDataOfBatch = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/NoticeSms/getDataOfBatch`, {params: params});
};

// 获取新名单发送通知，响应率概况图表
export const getChartDataOfBatch = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/NoticeSms/getChartDataOfBatch`, {params: params});
};

// 未访问H5页面
// 获取未访问H5页面实时数量
export const getCurDataByStatus = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/NoticeSms/getCurDataByStatus`, {params: params});
};

// 未访问H5页面选择人员查询
export const getSocialGroupNumByCondition = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/CrowdManagement/getSocialGroupNumByCondition`, {params: params});
};

// 未访问H5申请发送短信
export const applyForStatusSendSMS = params => {
    params.ProjectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/NoticeSms/applyForStatusSendSMS`,  params);
};

//查询短信模板
export const getNoticeTemplateByStatus = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/NoticeSms/getNoticeTemplateByStatus`, {params: params});
};

// 待人工清洗数据
// 获取待人工清洗数据列表
export const getDataOfClean = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/NoticeSms/getDataOfClean`, {params: params});
};

// 编辑待人工清洗数据
export const updateDataOfClean = params => {
    params.ProjectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/NoticeSms/updateDataOfClean`,  params);
};

// 访问后未填报员工信息单位数
// 获取访问后未填报员工信息单位数实时数量
export const getNoFillStaffData = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/NoticeSms/getNoFillStaffData`, {params: params});
};

// 访问后未填报员工信息单位数选择人员查询
export const getNoFillStaffByCondition = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/NoticeSms/getNoFillStaffByCondition`, {params: params});
};

// 访问后未填报员工信息单位数申请发送短信
export const applyForSendStaffNoFill = params => {
    params.ProjectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/NoticeSms/applyForSendStaffNoFill`,  params);
};

// 获取访问后未填报员工信息单位数发送通知概况预览
export const getNoFillDataOfBatch = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/NoticeSms/getNoFillDataOfBatch`, {params: params});
};

// 获取访问后未填报员工信息单位数图表
export const getNoFillChartOfBatch = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/NoticeSms/getNoFillChartOfBatch`, {params: params});
};

// 访问后未达到填报标准单位数
// 获取访问后未达到填报标准单位数实时数量
export const getLessFillStaffByCondition = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/NoticeSms/getLessFillStaffByCondition`, {params: params});
};

// 获取访问后未达到填报标准-可发通知单位数详情-可发通知人数
export const getLessFillStaffDetailByCondition = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/NoticeSms/getLessFillStaffDetailByCondition`, {params: params});
};

// 获取单位群体数据
export const getUnitFillSettingByType = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/CommonSettings/getUnitFillSettingByType`, {params: params});
};

// 访问后未达到填报标准单位数申请发送短信
export const applyForSendStaffForFill = params => {
    params.ProjectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/NoticeSms/applyForSendStaffForFill`,  params);
};

// 获取访问后未达到填报标准单位数的应训人员行业（场所）
export const getTradeByID = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/CommonSettings/getTradeByID`, {params: params});
};

// 新名单新增明细
// 获取新名单新增明细
export const getNewListSumData = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/GeneralizationStatistics/getNewListSumData`, {params: params});
};

// 获取新名单新增明细列表
export const getNewListAddDetail = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/GeneralizationStatistics/getNewListAddDetail`, {params: params});
};

// 回执失败短信重发
// 获取回执失败短信重发列表
export const getReceiptFailData = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/NoticeSms/getReceiptFailData`, {params: params});
};

// 回执失败短信重发
export const resendReceiptFailStaff = params => {
    params.ProjectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/NoticeSms/resendReceiptFailStaff`,  params);
};

//初始化项目
export const addInitialProject = params => {
    params.ProjectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.post(`${base}/sstwebapi/project/addInitialProject`,  params);
};

//获取行政区域信息递归列表
export const getLoopAreaDictionary = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/areaDictionary/getLoopAreaDictionary`, {params: params});
};

//获取初始化后的项目配置列表
export const getInitialedProjectSettingsItem = params => {
    params.projectId =parseInt(window.localStorage.getItem('projectId'))
    return axios.get(`${base}/sstwebapi/project/getInitialedProjectSettingsItem`, {params: params});
};

